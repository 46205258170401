<template>
	<v-list v-if="applications.length > 0" dense>
		<v-list-tile class="pointer">
			<w-checkbox
				v-model="applicationsAssignmentState"
				color="secondary"
				false-value="none"
				:indeterminate="applicationsAssignmentState === 'some'"
				:ripple="false"
				style="margin: 0"
				true-value="all"
			>
				<template v-slot:label>
					<v-flex mr-3 shrink style="width: 36px" />
					<v-flex v-t="'admin_group.manager.select_all_applications'" shrink />
				</template>
			</w-checkbox>
		</v-list-tile>
		<v-divider />
		<v-list-tile v-for="application in applications" :key="application.id" @click.stop="">
			<w-checkbox
				:value="usedApplications.includes(application.id)"
				:ripple="false"
				style="margin: 0"
				@input="updateApplicationAssignment(application, $event)"
			>
				<template v-slot:label>
					<v-flex shrink mr-3>
						<v-avatar size="36px">
							<v-img :src="application.logo" />
						</v-avatar>
					</v-flex>
					<v-flex shrink v-text="application.title" />
				</template>
			</w-checkbox>
		</v-list-tile>
	</v-list>
</template>

<script>
import GroupModuleGuard from '@/mixins/ModulesGuards/Group/GroupModuleGuard'

export default {
	name: 'ApplicationsList',
	mixins: [GroupModuleGuard],
	props: {
		value: {
			required: true,
			type: Array
		}
	},
	data: function () {
		return {
			applications: [],
			checkAll: false,
			usedApplications: []
		}
	},
	computed: {
		applicationsAssignmentState: {
			get: function () {
				const appIds = this.applications.map(app => app.id)

				let nbOfModulesInCommon = 0
				this.value.forEach(application => {
					if (appIds.includes(application.id)) {
						nbOfModulesInCommon++
					}
				})

				if (nbOfModulesInCommon === 0) {
					return 'none'
				} else if (nbOfModulesInCommon < appIds.length) {
					return 'some'
				} else {
					return 'all'
				}
			},
			set: function (val) {
				let toggleValue
				if (val === 'all') {
					toggleValue = true
				} else {
					toggleValue = false
				}
				this.updateApplicationsAssignments(toggleValue)
			}
		}
	},
	watch: {
		value: {
			deep: true,
			handler: function (val) {
				this.usedApplications = val.map(app => app.id)
				this.checkAll = Array.isArray(val) && this.applications.length === val.length
			},
			immediate: true
		}
	},
	mounted: function () {
		this.service.getApplications().then(applications => {
			this.applications = applications
		})
	},
	methods: {
		updateApplicationsAssignments: function (toggleValue) {
			const usedApplications = this.usedApplications
			this.applications.asyncForEach(async application => {
				if ((toggleValue && !usedApplications.includes(application.id)) || (!toggleValue && usedApplications.includes(application.id))) {
					await this.updateApplicationAssignment(application, toggleValue)
				}
			})
		},
		updateApplicationAssignment: function (application, toggleValue) {
			return this.service.updateApplicationAssignment(application.id, toggleValue).then(app => {
				let copiedValue = [...this.value]
				const index = copiedValue.findIndex(a => a.id === app.id)
				if (toggleValue && index == -1) {
					copiedValue.push(app)
				} else if (toggleValue) {
					copiedValue = copiedValue.map(a => {
						if (a.id === app.id) {
							return app
						}
						return a
					})
				} else if (!toggleValue && index > -1) {
					copiedValue.splice(index, 1)
				}
				this.$emit('input', copiedValue)
				return app
			})
		}
	}
}
</script>
